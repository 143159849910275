.footer{
  background: #081c3b;
  color: #fff;
  // padding: 20px 0;
  box-sizing: border-box;
  .logoFooter{
    padding: 20px 0;
    min-height: 60px;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #01050d;
    img{
      width: 110px;
    }
  }
  .infoFooter{
    padding: 20px 0;
    .container{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
      @media (min-width: 768px) {
        flex-direction: row;
      }
      .linksFooter{
        width: 100%;
        max-width: 500px;
        ul{
          width: 100%;
          list-style: none;
          margin: 0;
          padding: 0;
          display: inline-flex;
          flex-wrap: wrap;
          gap: 20px 10px;
          li{
            width: 50%;
            @media (min-width: 768px) {
              width: 33%;
            }
            a{
              color: #fff;
              text-decoration: none;
              font-size: 15px;
            }
          }
        }
      }
      .newsletter{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        gap: 10px;
        max-width: 100%;
        @media (min-width: 768px) {
          max-width: 350px;
          align-items: flex-end;
          text-align: right;
        }
        .label{
          font-size: 14px;
        }
        input{
          background: #fff;
          border: none;
          color: #000;
          font-size: 14px;
          padding: 6px 10px;
          text-align: center;
          line-height: 20px;
          box-sizing: border-box;
        }
        button{
          background: #ff9900;
          color: #000;
          border: none;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 600;
          padding: 6px 25px;
          cursor: pointer;
          line-height: 20px;
        }
      }
    }
  }
  .linksPolicy{
    list-style: none;
    padding: 15px 0;
    margin: 0;
    border-top: solid 1px #01050d;
    ul{
      list-style: none;
      margin: 0;
      padding: 0;
      display: inline-flex;
      gap: 30px;
      li{
        a{
          color: #fff;
          text-decoration: none;
          font-size: 15px;
        }
      }
    }
  }
  .copy{
    border-top: solid 1px #01050d;
    padding: 20px 0;
    font-size: 14px;
  }
}