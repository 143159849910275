.products{
  .container{
    display: flex;
    flex-direction: column;
    gap: 90px;
    .product{
      display: flex;
      flex-direction: column;
      gap: 20px;
      @media (min-width: 768px) {
        flex-direction: row;
        gap: 50px;
      }
      .image{
        max-width: 400px;
        img{
          width: 100%;
          height: auto;
          border: solid 1px #c0c0c0;
          border-radius: 4px;
        }
      }
      .info{
        width: 100%;
        .counter{
          font-size: 35px;
          font-weight: 500;
          font-family: Rubik;
        }
        .title{
          font-size: 20px;
          font-weight: bold;
        }
        .linkContainer{
          margin: 5px 0 28px 0;
          a{
            --bg-color: #ff9900;
            text-decoration: none;
            background-color: var(--bg-color, #ff9900);
            padding: 12px 16px;
            color: #000;
            border-radius: 4px;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: bold;
            font-size: 16px;
            transition: 0.3s;
            box-sizing: border-box;
            &:hover{
              color: #fff;
              text-decoration: none;
            }
            i{
              font-size: 38px;
              margin-left: -5px;
              margin-right: 10px;
              -webkit-text-stroke-color: var(--bg-color, #ff9900);
              -webkit-text-stroke-width: 0.02em;
              &::before{
                margin: 0;
              }
            }
          }
        }
        .description{
          font-size: 19px;
        }
      }
    }
  }
}