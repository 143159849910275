.hero{
  margin: 50px 0;
  .container{
    display: flex;
    flex-direction: column;
    gap: 25px;
    .title{
      width: 100%;
      h1{
        margin: 0;
        // text-transform: uppercase;
        font-weight: bold;
        font-size: 25px;
        line-height: 35px;
        @media (min-width: 576px) {
          font-size: 2.5rem;
          line-height: 55px;
        }
      }
    }
    .image{
      img{
        width: 100%;
        height: auto;
        max-width: 500px;
      }
    }
    .paragraph{
      p{
        font-size: 19px;
      }
    }
  }
}