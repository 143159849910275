.privacy{
  padding: 140px 0;
  position: relative;
  box-sizing: border-box;
  .container-html{
    margin: 50px 0 30px;
    h2 {
      font-weight: 600;
    }
    h3 {
      font-size: 19px;
      font-weight: 600;
      margin-top: 50px;
    }
  }
}