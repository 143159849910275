@import "./components/header/Header.module.scss";
@import "./components/login/Login.module.scss";
@import "./components/body/Body.module.scss";
@import "./components/sidebar/Sidebar.module.scss";
@import "./components/dropdown/Dropdown.module.scss";
@import "./components/categories/Categories.module.scss";
@import "./components/products/Products.module.scss";
@import "./components/addProduct/AddProduct.module.scss";
@import "./components/modal/Modal.module.scss";

.cms{
  height: 100%;
  font-family: Inter;
}
