.preHeader{
  background: #081c3b;
  color: #fff;
  position: fixed;
  width: 100%;
  z-index: 10;
  min-height: 53px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #01050d;
  .items{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    .share{
      display: flex;
      align-items: center;
      gap: 10px;
      .labelShare{
        display: flex;
        align-items: center;
        gap: 4px;
        span{
          line-height: 12px;
        }
      }
      .buttonShare{
        display: flex;
        align-items: center;
        gap: 10px;
        button{
          background: none;
          border: none;
          padding: 0;
          display: flex;
          align-items: center;
          span{
            color: #fff;
            font-size: 20px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
.header{
  border-bottom: 1px solid #EDEBE9;
  background: #fff;
  position: fixed;
  width: 100%;
  top: 53px;
  z-index: 10;
  box-shadow: 0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.13), 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.10);
  .items{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 90px;
    .left{
      display: flex;
      align-items: center;
      gap: 35px;
      .logo{
        img{
          max-width: 160px;
          width: 100%;
        }
      }
      .menu{
        display: none;
        @media (min-width: 768px) {
          display: block;
        }
        ul{
          list-style: none;
          margin: 0;
          padding: 0;
          display: inline-flex;
          gap: 24px;
          li{
            a{
              text-decoration: none;
              color: #000;
              font-size: 15px;
              font-weight: 600;
              &:hover{
                color: #0056b3;
              }
            }
          }
        }
      }
    }
    .right{
      display: none;
      border: solid 1px #c0c0c0;
      border-radius: 4px;
      padding: 5px;
      box-sizing: border-box;
      @media (min-width: 768px) {
        display: block;
      }
      .search{
        display: flex;
        align-items: center;
        max-width: 250px;
        width: 100%;
        input{
          width: 100%;
          background: none;
          border: none;
          color: #000;
          outline: none;
          font-size: 15px;
          padding: 3px 10px;
          box-sizing: border-box;
          font-family: Inter;
          line-height: 20px;
          &::placeholder{
            color: #081c3b;
            opacity: 0.7;
          }
        }
        button{
          background: none;
          border: none;
          display: flex;
          align-items: center;
          span{
            display: flex;
            align-items: center;
            font-size: 25px;
            color: #081c3b;
          }
        }
      }
      // .actionUser{
      //   display: flex;
      //   align-items: center;
      //   gap: 10px;
      //   .registrati{
      //     background: none;
      //     border: none;
      //     color: #081c3b;
      //     box-sizing: border-box;
      //     font-size: 15px;
      //     font-weight: 600;
      //   }
      //   .accedi{
      //     background: #081c3b;
      //     border: none;
      //     color: #fff;
      //     border-radius: 4px;
      //     padding: 5px 10px 3px;
      //     font-weight: 600;
      //     box-sizing: border-box;
      //     font-size: 14px;
      //   }
      // }
    }
    .buttonMenuMobile{
      display: block;
      button{
        background: none;
        border: none;
        display: flex;
        align-items: center;
        span{
          font-size: 30px;
        }
      }
      @media (min-width: 768px) {
        display: none;
      }
    }
  }
}

.menuCategory{
  position: fixed;
  top: 0;
  background: #fff;
  width: 100%;
  z-index: 9;
  visibility: hidden;
  opacity: 0;
  transition: all .3s ease-out;

  @media (max-width: 768px) {
    height: calc(100% - 145px);
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      background: #F3F2F1;
    }

    &::-webkit-scrollbar-thumb {
      background: #C8C6C4;
      border-radius: 50px;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }
  }

  &.open {
    top: 144px;
    visibility: visible;
    opacity: 1;
  }
  .search{
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    border: solid 1px #c0c0c0;
    border-radius: 4px;
    padding: 5px;
    box-sizing: border-box;
    @media (min-width: 768px) {
      display: none;
    }
    input{
      width: 100%;
      background: none;
      border: none;
      color: #000;
      outline: none;
      font-size: 15px;
      padding: 3px 10px;
      font-family: Inter;
      line-height: 20px;
      &::placeholder{
        color: #081c3b;
        opacity: 0.7;
      }
    }
    button{
      background: none;
      border: none;
      display: flex;
      align-items: center;
      span{
        display: flex;
        align-items: center;
        font-size: 25px;
        color: #081c3b;
      }
    }
  }
  ul {
    list-style: none;
    padding: 30px 0;
    margin: 0;
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 0;
    
    @media (min-width: 768px) {
      gap: 10px 30px;
    }
    li{
      width: 100%;
      @media (min-width: 768px) {
        width: auto;
      }
      a{
        display: block;
        padding: 8px 0;
        text-decoration: none;
        color: #000;
        font-size: 15px;
        font-weight: 600;
        &:hover{
          color: #0056b3;
        }
      }
    }
  }
  .actionUser{
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: solid 1px #EDEBE9;
    padding: 20px 0;
    gap: 10px;
    .registrati{
      background: none;
      border: none;
      color: #081c3b;
      box-sizing: border-box;
      font-size: 15px;
      font-weight: 600;
    }
    .accedi{
      background: #081c3b;
      border: none;
      color: #fff;
      border-radius: 4px;
      padding: 5px 10px 3px;
      font-weight: 600;
      box-sizing: border-box;
      font-size: 14px;
    }
  }
}