
.cms-sidebar{
  width: 30%;
  max-width: 350px;
  background: #081c3b;
  ul{
    margin: 0;
    padding: 20px 0;
    list-style: none;
    li{
      &:has(button.active){
        background: #f90;
        position: relative;
        &::after{
          content: "";
          height: 0px;
          width: 0px;
          position: absolute;
          border-right: solid 20px #F5F5F7;
          border-bottom: solid 20px transparent;
          border-top: solid 20px transparent;
          right: 0;
          margin-top: auto;
          margin-bottom: auto;
          top: 0;
          bottom: 0;
        }
      }
      button{
        background: none;
        border: none;
        padding: 12px 15px 10px 15px;
        box-sizing: border-box;
        color: #fff;
        font-size: 16px;
        width: 100%;
        text-align: left;
        &:hover{
          text-decoration: underline;
          text-underline-offset: 4px;
        }
        &.active{
          color: #081c3b;
          font-weight: 600;
          text-decoration: none;
        }
      }
    }
  }
}