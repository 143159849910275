
.cms-dropdown{
  width: 100%;
  position: relative;
  .cms-dropdown-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    width: 100%;
    height: 32px;
    background: #fff;
    border: solid 1px #767676;
    border-radius: 4px;
    padding: 7px 10px;
    cursor: pointer;
    font-family: Inter;
    font-size: 14px;
    box-sizing: border-box;
    .cms-dropdown-header-title-placeholder{
      color: rgb(96, 94, 92);
      font-family: Inter;
    }
  }
  .cms-dropdown-list{
    position: absolute;
    z-index: 9;
    width: 100%;
    // max-width: 320px;
    max-height: 250px;
    background: #fff;
    box-sizing: border-box;
    border-radius: 0px 0px 2px 2px;
    box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
    overflow-y: auto;
    .cms-dropdown-list-item{
      display: flex;
      align-items: center;
      padding: 5px 8px;
      cursor: pointer;
      width: 100%;
      min-height: 36px;
      box-sizing: border-box;
      background: none;
      border: none;
      font-family: Inter;
      font-size: 14px;
      &:hover{
        background: rgb(243, 242, 241);
      }
      &.active{
        background: rgb(237, 235, 233);
      }
    }
  }
}