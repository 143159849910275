.cms-header{
  width: 100%;
  background: #081c3b;
  border-bottom: 1px solid #01050d;
  min-height: 63px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  box-sizing: border-box;
  .cms-header-logo{
    img{
      height: 25px;
    }
  }
  .cms-header-menu{
    display: flex;
    align-items: center;
    gap: 15px;
    a{
      color: #fff;
      font-size: 14px;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      gap: 4px;
      span{
        display: flex;
        align-items: center;
      }
      &:hover{
        text-decoration: underline;
        text-underline-offset: 4px;
      }
    }
    button{
      background: none;
      border: none;
      cursor: pointer;
      color: #fff;
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      font-family: Inter;
      span{
        display: flex;
        align-items: center;
      }
      &:hover{
        text-decoration: underline;
        text-underline-offset: 4px;
      }
    }
  }
}