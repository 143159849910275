
.cms-products{
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  height: calc(100% - 60px);
  overflow: hidden auto;
  .cms-filtri{
    margin-bottom: 24px;
    max-width: 400px;
    .cms-labelFiltri{
      margin-bottom: 8px;
      font-weight: 600;
    }
    .cms-filtersButtons{
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
      .cms-contRemoveFilters{
        width: 190px;
        .cms-removeFilter{
          font-family: Inter;
          background: none;
          border: none;
          font-weight: 600;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 4px;
        }
      }
    }
  }
  .cms-noResults{
    margin-bottom: 20px;
    font-weight: 600;
  }
  .cms-countResults{
    margin-bottom: 20px;
    font-weight: 600;
  }
  .cms-list-items{
    .cms-item{
      display: flex;
      align-items: flex-start;
      background: #fff;
      border: solid 1px #e2e8f0;
      padding: 20px 10px;
      margin-bottom: 20px;
      box-sizing: border-box;
      gap: 20px;
      .cms-img{
        img{
          width: 100px;
        }
      }
      .cms-info{
        .cms-title{
          font-weight: 600;
          margin-bottom: 10px;
        }
        .cms-description{
          line-height: 20px;
          margin-bottom: 10px;
        }
        .cms-info{
          margin-bottom: 10px;
          .cms-categoria{
            text-transform: capitalize;
            font-size: 12px;
            color: #081c3b;
            font-weight: 600;
          }
        }
        .cms-actions{
          display: flex;
          align-items: center;
          gap: 15px;
          button{
            height: 32px;
            cursor: pointer;
            padding: 7px 10px;
            box-sizing: border-box;
            font-family: Inter;
            display: flex;
            align-items: center;
            justify-content: center;
            border: solid 1px #767676;
          }
        }
      }
    }
  }
}