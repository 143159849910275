@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?35381910');
  src: url('../font/fontello.eot?35381910#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?35381910') format('woff2'),
       url('../font/fontello.woff?35381910') format('woff'),
       url('../font/fontello.ttf?35381910') format('truetype'),
       url('../font/fontello.svg?35381910#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?35381910#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-videocam:before { content: '\e800'; } /* '' */
.icon-camera:before { content: '\e801'; } /* '' */
.icon-photo:before { content: '\e802'; } /* '' */
.icon-heart:before { content: '\e803'; } /* '' */
.icon-mobile:before { content: '\e804'; } /* '' */
.icon-camera-1:before { content: '\e805'; } /* '' */
.icon-picture:before { content: '\e806'; } /* '' */
.icon-camera-alt:before { content: '\e807'; } /* '' */
.icon-camera-2:before { content: '\e808'; } /* '' */
.icon-camera-outline:before { content: '\e809'; } /* '' */
.icon-videocam-1:before { content: '\e80a'; } /* '' */
.icon-plus:before { content: '\e80b'; } /* '' */
.icon-plus-1:before { content: '\e80c'; } /* '' */
.icon-cancel-2:before { content: '\e80d'; } /* '' */
.icon-upload:before { content: '\e80e'; } /* '' */
.icon-emo-happy:before { content: '\e80f'; } /* '' */
.icon-emo-wink:before { content: '\e810'; } /* '' */
.icon-emo-unhappy:before { content: '\e811'; } /* '' */
.icon-emo-sleep:before { content: '\e812'; } /* '' */
.icon-emo-wink2:before { content: '\e813'; } /* '' */
.icon-emo-thumbsup:before { content: '\e814'; } /* '' */
.icon-emo-devil:before { content: '\e815'; } /* '' */
.icon-emo-surprised:before { content: '\e816'; } /* '' */
.icon-emo-tongue:before { content: '\e817'; } /* '' */
.icon-emo-coffee:before { content: '\e818'; } /* '' */
.icon-emo-sunglasses:before { content: '\e819'; } /* '' */
.icon-emo-displeased:before { content: '\e81a'; } /* '' */
.icon-emo-beer:before { content: '\e81b'; } /* '' */
.icon-emo-grin:before { content: '\e81c'; } /* '' */
.icon-emo-angry:before { content: '\e81d'; } /* '' */
.icon-emo-saint:before { content: '\e81e'; } /* '' */
.icon-emo-cry:before { content: '\e81f'; } /* '' */
.icon-emo-shoot:before { content: '\e820'; } /* '' */
.icon-emo-squint:before { content: '\e821'; } /* '' */
.icon-emo-laugh:before { content: '\e822'; } /* '' */
.icon-user:before { content: '\e823'; } /* '' */
.icon-heart-1:before { content: '\e824'; } /* '' */
.icon-heart-empty:before { content: '\e825'; } /* '' */
.icon-star:before { content: '\e826'; } /* '' */
.icon-star-empty:before { content: '\e827'; } /* '' */
.icon-mail:before { content: '\e828'; } /* '' */
.icon-search:before { content: '\e829'; } /* '' */
.icon-th-large:before { content: '\e82a'; } /* '' */
.icon-cancel:before { content: '\e82b'; } /* '' */
.icon-ok-circled2:before { content: '\e82c'; } /* '' */
.icon-ok-circled:before { content: '\e82d'; } /* '' */
.icon-plus-3:before { content: '\e82e'; } /* '' */
.icon-minus:before { content: '\e82f'; } /* '' */
.icon-home:before { content: '\e830'; } /* '' */
.icon-thumbs-up:before { content: '\e831'; } /* '' */
.icon-thumbs-down:before { content: '\e832'; } /* '' */
.icon-eye:before { content: '\e833'; } /* '' */
.icon-spin4:before { content: '\e834'; } /* '' */
.icon-eye-off:before { content: '\e835'; } /* '' */
.icon-lock-open:before { content: '\e836'; } /* '' */
.icon-lock:before { content: '\e837'; } /* '' */
.icon-pencil:before { content: '\e838'; } /* '' */
.icon-chat:before { content: '\e839'; } /* '' */
.icon-comment:before { content: '\e83a'; } /* '' */
.icon-bell:before { content: '\e83b'; } /* '' */
.icon-folder-open:before { content: '\e83c'; } /* '' */
.icon-folder:before { content: '\e83d'; } /* '' */
.icon-cog-alt:before { content: '\e83e'; } /* '' */
.icon-cog:before { content: '\e83f'; } /* '' */
.icon-location:before { content: '\e840'; } /* '' */
.icon-attention:before { content: '\e841'; } /* '' */
.icon-attention-circled:before { content: '\e842'; } /* '' */
.icon-trash-empty:before { content: '\e843'; } /* '' */
.icon-calendar:before { content: '\e844'; } /* '' */
.icon-up-big:before { content: '\e845'; } /* '' */
.icon-right-big:before { content: '\e846'; } /* '' */
.icon-left-big:before { content: '\e847'; } /* '' */
.icon-down-big:before { content: '\e848'; } /* '' */
.icon-right-hand:before { content: '\e849'; } /* '' */
.icon-left-hand:before { content: '\e84a'; } /* '' */
.icon-up-hand:before { content: '\e84b'; } /* '' */
.icon-down-hand:before { content: '\e84c'; } /* '' */
.icon-cloud:before { content: '\e84d'; } /* '' */
.icon-flash:before { content: '\e84e'; } /* '' */
.icon-umbrella:before { content: '\e84f'; } /* '' */
.icon-leaf:before { content: '\e850'; } /* '' */
.icon-off:before { content: '\e851'; } /* '' */
.icon-signal:before { content: '\e852'; } /* '' */
.icon-block:before { content: '\e853'; } /* '' */
.icon-floppy:before { content: '\e854'; } /* '' */
.icon-credit-card:before { content: '\e855'; } /* '' */
.icon-attach:before { content: '\e856'; } /* '' */
.icon-edit:before { content: '\e857'; } /* '' */
.icon-print:before { content: '\e858'; } /* '' */
.icon-doc:before { content: '\e859'; } /* '' */
.icon-phone:before { content: '\e85a'; } /* '' */
.icon-basket:before { content: '\e85b'; } /* '' */
.icon-login:before { content: '\e85c'; } /* '' */
.icon-logout:before { content: '\e85d'; } /* '' */
.icon-volume-off:before { content: '\e85e'; } /* '' */
.icon-volume-down:before { content: '\e85f'; } /* '' */
.icon-volume-up:before { content: '\e860'; } /* '' */
.icon-clock:before { content: '\e861'; } /* '' */
.icon-arrows-cw:before { content: '\e862'; } /* '' */
.icon-award:before { content: '\e863'; } /* '' */
.icon-globe:before { content: '\e864'; } /* '' */
.icon-list-alt:before { content: '\e865'; } /* '' */
.icon-qrcode:before { content: '\e866'; } /* '' */
.icon-check:before { content: '\e867'; } /* '' */
.icon-chart-bar:before { content: '\e868'; } /* '' */
.icon-users:before { content: '\e869'; } /* '' */
.icon-user-add:before { content: '\e86a'; } /* '' */
.icon-user-1:before { content: '\e86b'; } /* '' */
.icon-mail-1:before { content: '\e86c'; } /* '' */
.icon-heart-2:before { content: '\e86d'; } /* '' */
.icon-heart-empty-1:before { content: '\e86e'; } /* '' */
.icon-star-1:before { content: '\e86f'; } /* '' */
.icon-star-empty-1:before { content: '\e870'; } /* '' */
.icon-picture-1:before { content: '\e871'; } /* '' */
.icon-camera-3:before { content: '\e872'; } /* '' */
.icon-layout:before { content: '\e873'; } /* '' */
.icon-menu-1:before { content: '\e874'; } /* '' */
.icon-cancel-1:before { content: '\e875'; } /* '' */
.icon-check-1:before { content: '\e876'; } /* '' */
.icon-minus-1:before { content: '\e877'; } /* '' */
.icon-home-1:before { content: '\e878'; } /* '' */
.icon-thumbs-up-1:before { content: '\e879'; } /* '' */
.icon-thumbs-down-1:before { content: '\e87a'; } /* '' */
.icon-location-1:before { content: '\e87b'; } /* '' */
.icon-doc-1:before { content: '\e87c'; } /* '' */
.icon-docs-1:before { content: '\e87d'; } /* '' */
.icon-login-1:before { content: '\e87e'; } /* '' */
.icon-logout-1:before { content: '\e87f'; } /* '' */
.icon-clock-1:before { content: '\e880'; } /* '' */
.icon-down-open-big:before { content: '\e881'; } /* '' */
.icon-left-open-big:before { content: '\e882'; } /* '' */
.icon-right-open-big:before { content: '\e883'; } /* '' */
.icon-up-open-big:before { content: '\e884'; } /* '' */
.icon-trophy:before { content: '\e885'; } /* '' */
.icon-signal-1:before { content: '\e886'; } /* '' */
.icon-back-in-time:before { content: '\e887'; } /* '' */
.icon-battery:before { content: '\e888'; } /* '' */
.icon-mobile-2:before { content: '\e889'; } /* '' */
.icon-globe-1:before { content: '\e88a'; } /* '' */
.icon-mail-2:before { content: '\e88b'; } /* '' */
.icon-ok:before { content: '\e88c'; } /* '' */
.icon-clock-2:before { content: '\e88d'; } /* '' */
.icon-down:before { content: '\e88e'; } /* '' */
.icon-left:before { content: '\e88f'; } /* '' */
.icon-right:before { content: '\e890'; } /* '' */
.icon-up:before { content: '\e891'; } /* '' */
.icon-at-1:before { content: '\e892'; } /* '' */
.icon-group:before { content: '\e893'; } /* '' */
.icon-basket-1:before { content: '\e894'; } /* '' */
.icon-cogs:before { content: '\e895'; } /* '' */
.icon-chat-2:before { content: '\e896'; } /* '' */
.icon-comment-alt:before { content: '\e897'; } /* '' */
.icon-attention-1:before { content: '\e898'; } /* '' */
.icon-clock-3:before { content: '\e899'; } /* '' */
.icon-search-1:before { content: '\e89a'; } /* '' */
.icon-location-3:before { content: '\e89b'; } /* '' */
.icon-amazon-1:before { content: '\e89c'; } /* '' */
.icon-star-half:before { content: '\e89d'; } /* '' */
.icon-ok-1:before { content: '\e89e'; } /* '' */
.icon-cancel-circled:before { content: '\e89f'; } /* '' */
.icon-cancel-circled2:before { content: '\e8a0'; } /* '' */
.icon-plus-circled:before { content: '\e8a1'; } /* '' */
.icon-minus-circled:before { content: '\e8a2'; } /* '' */
.icon-link:before { content: '\e8a3'; } /* '' */
.icon-bookmark:before { content: '\e8a4'; } /* '' */
.icon-download-1:before { content: '\e8a5'; } /* '' */
.icon-upload-2:before { content: '\e8a6'; } /* '' */
.icon-export:before { content: '\e8a7'; } /* '' */
.icon-gift:before { content: '\e8a8'; } /* '' */
.icon-search-2:before { content: '\e8a9'; } /* '' */
.icon-attach-2:before { content: '\e8aa'; } /* '' */
.icon-folder-1:before { content: '\e8ab'; } /* '' */
.icon-basket-2:before { content: '\e8ac'; } /* '' */
.icon-list:before { content: '\e8ad'; } /* '' */
.icon-list-add:before { content: '\e8ae'; } /* '' */
.icon-dot:before { content: '\e8af'; } /* '' */
.icon-dot-2:before { content: '\e8b0'; } /* '' */
.icon-dot-3:before { content: '\e8b1'; } /* '' */
.icon-star-2:before { content: '\e8b2'; } /* '' */
.icon-star-empty-2:before { content: '\e8b3'; } /* '' */
.icon-heart-3:before { content: '\e8b4'; } /* '' */
.icon-home-2:before { content: '\e8b5'; } /* '' */
.icon-stopwatch:before { content: '\e8b6'; } /* '' */
.icon-mail-3:before { content: '\e8b7'; } /* '' */
.icon-heart-4:before { content: '\e8b8'; } /* '' */
.icon-heart-empty-2:before { content: '\e8b9'; } /* '' */
.icon-star-empty-3:before { content: '\e8ba'; } /* '' */
.icon-star-3:before { content: '\e8bb'; } /* '' */
.icon-picture-2:before { content: '\e8bc'; } /* '' */
.icon-ok-circled-2:before { content: '\e8bd'; } /* '' */
.icon-ok-circled2-1:before { content: '\e8be'; } /* '' */
.icon-cancel-circled-2:before { content: '\e8bf'; } /* '' */
.icon-cancel-circled2-1:before { content: '\e8c0'; } /* '' */
.icon-home-3:before { content: '\e8c1'; } /* '' */
.icon-attach-3:before { content: '\e8c2'; } /* '' */
.icon-download-alt:before { content: '\e8c3'; } /* '' */
.icon-pencil-1:before { content: '\e8c4'; } /* '' */
.icon-edit-1:before { content: '\e8c5'; } /* '' */
.icon-print-1:before { content: '\e8c6'; } /* '' */
.icon-comment-alt-1:before { content: '\e8c7'; } /* '' */
.icon-comment-2:before { content: '\e8c8'; } /* '' */
.icon-warning:before { content: '\e8c9'; } /* '' */
.icon-location-circled:before { content: '\e8ca'; } /* '' */
.icon-location-4:before { content: '\e8cb'; } /* '' */
.icon-trash-1:before { content: '\e8cc'; } /* '' */
.icon-phone-1:before { content: '\e8cd'; } /* '' */
.icon-zoom-in:before { content: '\e8ce'; } /* '' */
.icon-zoom-out:before { content: '\e8cf'; } /* '' */
.icon-check-2:before { content: '\e8d0'; } /* '' */
.icon-check-empty-1:before { content: '\e8d1'; } /* '' */
.icon-gift-1:before { content: '\e8d2'; } /* '' */
.icon-fire:before { content: '\e8d3'; } /* '' */
.icon-credit-card-1:before { content: '\e8d4'; } /* '' */
.icon-male-1:before { content: '\e8d5'; } /* '' */
.icon-female-1:before { content: '\e8d6'; } /* '' */
.icon-mail-4:before { content: '\e8d7'; } /* '' */
.icon-comment-3:before { content: '\e8d8'; } /* '' */
.icon-cog-1:before { content: '\e8d9'; } /* '' */
.icon-pencil-2:before { content: '\e8da'; } /* '' */
.icon-thumbs-up-2:before { content: '\e8db'; } /* '' */
.icon-attach-4:before { content: '\e8dc'; } /* '' */
.icon-star-4:before { content: '\e8dd'; } /* '' */
.icon-heart-5:before { content: '\e8de'; } /* '' */
.icon-heart-empty-3:before { content: '\e8df'; } /* '' */
.icon-search-4:before { content: '\e8e0'; } /* '' */
.icon-picture-3:before { content: '\e8e1'; } /* '' */
.icon-ok-2:before { content: '\e8e2'; } /* '' */
.icon-cancel-3:before { content: '\e8e3'; } /* '' */
.icon-attach-5:before { content: '\e8e4'; } /* '' */
.icon-logout-3:before { content: '\e8e5'; } /* '' */
.icon-block-1:before { content: '\e8e6'; } /* '' */
.icon-email:before { content: '\e8e7'; } /* '' */
.icon-windows:before { content: '\e8e8'; } /* '' */
.icon-chrome-1:before { content: '\e8e9'; } /* '' */
.icon-paypal:before { content: '\e8ea'; } /* '' */
.icon-android:before { content: '\e8eb'; } /* '' */
.icon-w3c:before { content: '\e8ec'; } /* '' */
.icon-html5:before { content: '\e8ed'; } /* '' */
.icon-ie:before { content: '\e8ee'; } /* '' */
.icon-twitter-1:before { content: '\e8ef'; } /* '' */
.icon-facebook-circled:before { content: '\e8f0'; } /* '' */
.icon-facebook:before { content: '\e8f1'; } /* '' */
.icon-down-open:before { content: '\f004'; } /* '' */
.icon-up-open:before { content: '\f005'; } /* '' */
.icon-right-open:before { content: '\f006'; } /* '' */
.icon-left-open:before { content: '\f007'; } /* '' */
.icon-login-2:before { content: '\f02c'; } /* '' */
.icon-logout-2:before { content: '\f02d'; } /* '' */
.icon-download:before { content: '\f02e'; } /* '' */
.icon-upload-1:before { content: '\f02f'; } /* '' */
.icon-location-2:before { content: '\f031'; } /* '' */
.icon-videocam-2:before { content: '\f03a'; } /* '' */
.icon-chat-1:before { content: '\f03d'; } /* '' */
.icon-user-2:before { content: '\f061'; } /* '' */
.icon-users-1:before { content: '\f064'; } /* '' */
.icon-attach-1:before { content: '\f06a'; } /* '' */
.icon-ok-circled-1:before { content: '\f06d'; } /* '' */
.icon-cancel-circled-1:before { content: '\f06e'; } /* '' */
.icon-link-ext:before { content: '\f08e'; } /* '' */
.icon-check-empty:before { content: '\f096'; } /* '' */
.icon-bookmark-empty:before { content: '\f097'; } /* '' */
.icon-phone-squared:before { content: '\f098'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-docs:before { content: '\f0c5'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-table:before { content: '\f0ce'; } /* '' */
.icon-mail-alt:before { content: '\f0e0'; } /* '' */
.icon-gauge:before { content: '\f0e4'; } /* '' */
.icon-comment-empty:before { content: '\f0e5'; } /* '' */
.icon-chat-empty:before { content: '\f0e6'; } /* '' */
.icon-lightbulb:before { content: '\f0eb'; } /* '' */
.icon-download-cloud:before { content: '\f0ed'; } /* '' */
.icon-upload-cloud:before { content: '\f0ee'; } /* '' */
.icon-bell-alt:before { content: '\f0f3'; } /* '' */
.icon-coffee:before { content: '\f0f4'; } /* '' */
.icon-doc-text:before { content: '\f0f6'; } /* '' */
.icon-medkit:before { content: '\f0fa'; } /* '' */
.icon-plus-squared:before { content: '\f0fe'; } /* '' */
.icon-angle-double-left:before { content: '\f100'; } /* '' */
.icon-angle-double-right:before { content: '\f101'; } /* '' */
.icon-angle-double-up:before { content: '\f102'; } /* '' */
.icon-angle-double-down:before { content: '\f103'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-desktop:before { content: '\f108'; } /* '' */
.icon-laptop:before { content: '\f109'; } /* '' */
.icon-tablet:before { content: '\f10a'; } /* '' */
.icon-mobile-1:before { content: '\f10b'; } /* '' */
.icon-circle-empty:before { content: '\f10c'; } /* '' */
.icon-circle:before { content: '\f111'; } /* '' */
.icon-folder-empty:before { content: '\f114'; } /* '' */
.icon-folder-open-empty:before { content: '\f115'; } /* '' */
.icon-smile:before { content: '\f118'; } /* '' */
.icon-frown:before { content: '\f119'; } /* '' */
.icon-meh:before { content: '\f11a'; } /* '' */
.icon-code:before { content: '\f121'; } /* '' */
.icon-star-half-alt:before { content: '\f123'; } /* '' */
.icon-direction:before { content: '\f124'; } /* '' */
.icon-unlink:before { content: '\f127'; } /* '' */
.icon-mic:before { content: '\f130'; } /* '' */
.icon-mute:before { content: '\f131'; } /* '' */
.icon-shield:before { content: '\f132'; } /* '' */
.icon-lock-open-alt:before { content: '\f13e'; } /* '' */
.icon-ellipsis:before { content: '\f141'; } /* '' */
.icon-ellipsis-vert:before { content: '\f142'; } /* '' */
.icon-ticket:before { content: '\f145'; } /* '' */
.icon-minus-squared:before { content: '\f146'; } /* '' */
.icon-minus-squared-alt:before { content: '\f147'; } /* '' */
.icon-ok-squared:before { content: '\f14a'; } /* '' */
.icon-link-ext-alt:before { content: '\f14c'; } /* '' */
.icon-export-alt:before { content: '\f14d'; } /* '' */
.icon-euro:before { content: '\f153'; } /* '' */
.icon-doc-inv:before { content: '\f15b'; } /* '' */
.icon-doc-text-inv:before { content: '\f15c'; } /* '' */
.icon-thumbs-up-alt:before { content: '\f164'; } /* '' */
.icon-thumbs-down-alt:before { content: '\f165'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-female:before { content: '\f182'; } /* '' */
.icon-male:before { content: '\f183'; } /* '' */
.icon-sun:before { content: '\f185'; } /* '' */
.icon-moon:before { content: '\f186'; } /* '' */
.icon-plus-squared-alt:before { content: '\f196'; } /* '' */
.icon-language:before { content: '\f1ab'; } /* '' */
.icon-child:before { content: '\f1ae'; } /* '' */
.icon-file-pdf:before { content: '\f1c1'; } /* '' */
.icon-file-word:before { content: '\f1c2'; } /* '' */
.icon-file-excel:before { content: '\f1c3'; } /* '' */
.icon-file-powerpoint:before { content: '\f1c4'; } /* '' */
.icon-file-image:before { content: '\f1c5'; } /* '' */
.icon-file-archive:before { content: '\f1c6'; } /* '' */
.icon-file-audio:before { content: '\f1c7'; } /* '' */
.icon-file-video:before { content: '\f1c8'; } /* '' */
.icon-file-code:before { content: '\f1c9'; } /* '' */
.icon-history:before { content: '\f1da'; } /* '' */
.icon-circle-thin:before { content: '\f1db'; } /* '' */
.icon-sliders:before { content: '\f1de'; } /* '' */
.icon-share:before { content: '\f1e0'; } /* '' */
.icon-share-squared:before { content: '\f1e1'; } /* '' */
.icon-soccer-ball:before { content: '\f1e3'; } /* '' */
.icon-wifi:before { content: '\f1eb'; } /* '' */
.icon-paypal-1:before { content: '\f1ed'; } /* '' */
.icon-cc-visa:before { content: '\f1f0'; } /* '' */
.icon-cc-mastercard:before { content: '\f1f1'; } /* '' */
.icon-cc-discover:before { content: '\f1f2'; } /* '' */
.icon-cc-amex:before { content: '\f1f3'; } /* '' */
.icon-cc-paypal:before { content: '\f1f4'; } /* '' */
.icon-cc-stripe:before { content: '\f1f5'; } /* '' */
.icon-bell-off:before { content: '\f1f6'; } /* '' */
.icon-bell-off-empty:before { content: '\f1f7'; } /* '' */
.icon-trash:before { content: '\f1f8'; } /* '' */
.icon-copyright:before { content: '\f1f9'; } /* '' */
.icon-at:before { content: '\f1fa'; } /* '' */
.icon-birthday:before { content: '\f1fd'; } /* '' */
.icon-chart-area:before { content: '\f1fe'; } /* '' */
.icon-chart-pie:before { content: '\f200'; } /* '' */
.icon-toggle-off:before { content: '\f204'; } /* '' */
.icon-toggle-on:before { content: '\f205'; } /* '' */
.icon-shirtsinbulk:before { content: '\f214'; } /* '' */
.icon-cart-plus:before { content: '\f217'; } /* '' */
.icon-cart-arrow-down:before { content: '\f218'; } /* '' */
.icon-street-view:before { content: '\f21d'; } /* '' */
.icon-heartbeat:before { content: '\f21e'; } /* '' */
.icon-venus:before { content: '\f221'; } /* '' */
.icon-mars:before { content: '\f222'; } /* '' */
.icon-whatsapp:before { content: '\f232'; } /* '' */
.icon-user-plus:before { content: '\f234'; } /* '' */
.icon-user-times:before { content: '\f235'; } /* '' */
.icon-expeditedssl:before { content: '\f23e'; } /* '' */
.icon-battery-4:before { content: '\f240'; } /* '' */
.icon-battery-3:before { content: '\f241'; } /* '' */
.icon-battery-2:before { content: '\f242'; } /* '' */
.icon-battery-1:before { content: '\f243'; } /* '' */
.icon-battery-0:before { content: '\f244'; } /* '' */
.icon-safari:before { content: '\f267'; } /* '' */
.icon-chrome:before { content: '\f268'; } /* '' */
.icon-firefox:before { content: '\f269'; } /* '' */
.icon-opera:before { content: '\f26a'; } /* '' */
.icon-internet-explorer:before { content: '\f26b'; } /* '' */
.icon-television:before { content: '\f26c'; } /* '' */
.icon-amazon:before { content: '\f270'; } /* '' */
.icon-calendar-plus-o:before { content: '\f271'; } /* '' */
.icon-calendar-minus-o:before { content: '\f272'; } /* '' */
.icon-calendar-times-o:before { content: '\f273'; } /* '' */
.icon-calendar-check-o:before { content: '\f274'; } /* '' */
.icon-commenting:before { content: '\f27a'; } /* '' */
.icon-commenting-o:before { content: '\f27b'; } /* '' */
.icon-credit-card-alt:before { content: '\f283'; } /* '' */
.icon-bluetooth:before { content: '\f293'; } /* '' */
.icon-envira:before { content: '\f299'; } /* '' */
.icon-universal-access:before { content: '\f29a'; } /* '' */
.icon-low-vision:before { content: '\f2a8'; } /* '' */
.icon-handshake-o:before { content: '\f2b5'; } /* '' */
.icon-envelope-open:before { content: '\f2b6'; } /* '' */
.icon-envelope-open-o:before { content: '\f2b7'; } /* '' */
.icon-address-book:before { content: '\f2b9'; } /* '' */
.icon-address-book-o:before { content: '\f2ba'; } /* '' */
.icon-address-card:before { content: '\f2bb'; } /* '' */
.icon-address-card-o:before { content: '\f2bc'; } /* '' */
.icon-user-circle:before { content: '\f2bd'; } /* '' */
.icon-user-circle-o:before { content: '\f2be'; } /* '' */
.icon-user-o:before { content: '\f2c0'; } /* '' */
.icon-id-card:before { content: '\f2c2'; } /* '' */
.icon-id-card-o:before { content: '\f2c3'; } /* '' */
.icon-facebook-1:before { content: '\f300'; } /* '' */
.icon-facebook-rect:before { content: '\f301'; } /* '' */
.icon-twitter-squared:before { content: '\f304'; } /* '' */
.icon-facebook-squared:before { content: '\f308'; } /* '' */
.icon-instagram-1:before { content: '\f31e'; } /* '' */
.icon-instagram-filled:before { content: '\f31f'; } /* '' */
.icon-instagram-2:before { content: '\f32d'; } /* '' */
.icon-comment-1:before { content: '\f4ac'; } /* '' */
.icon-newspaper:before { content: '\f4f0'; } /* '' */
.icon-camera-4:before { content: '\f4f7'; } /* '' */
.icon-search-3:before { content: '\f50d'; } /* '' */
