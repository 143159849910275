
.cms-modal{
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  bottom: 0;
  .cms-modal-container{
    width: 100%;
    max-width: 500px;
    box-sizing: border-box;
    background: #fff;
    .cms-modal-header{
      box-sizing: border-box;
      min-height: 40px;
      padding: 16px 24px;
      border-bottom: solid 1px #e7e7e7;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      h4{
        margin: 0;
        font-size: 20px;
      }
      button{
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        span{
          display: flex;
          align-items: center;
        }
      }
    }
    .cms-modal-body{
      box-sizing: border-box;
      padding: 16px 24px;
    }
    .cms-modal-footer{
      box-sizing: border-box;
      min-height: 40px;
      padding: 16px 24px;
      border-top: solid 1px #e7e7e7;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      button{
        height: 32px;
        cursor: pointer;
        padding: 7px 10px;
        box-sizing: border-box;
        font-family: Inter;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 1px #767676;
      }
    }
  }
}