
.cms-login{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 20px;
  background: #F5F5F7;
  .cms-logo-login{
    img{
      height: 30px;
    }
  }
  .cms-login-container{
    width: 90%;
    max-width: 480px;
    background: #fff;
    box-shadow: 0 4px 16px rgb(0, 0, 0, .04);
    padding: 24px;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .cms-login-field{
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 2px;
      label{
        font-weight: 500;
      }
      input{
        width: 100%;
        font-size: 1em;
        padding: 0.75em 1em;
        border-radius: 4px;
        box-sizing: border-box;
        margin: 0;
        border: 1px solid #eee;
        background-color: #eee;
        color: #121218;
        font-family: Inter;
        &.cms-error{
          border-color: red;
        }
      }
      .cms-login-show-password{
        background: none;
        border: none;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 34px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .cms-login-button{
        cursor: pointer;
        padding: 14px 16px;
        background: #121218;
        color: #fff;
        text-align: center;
        width: 100%;
        border: none;
        border-radius: 4px;
        text-transform: uppercase;
        line-height: 20px;
      }
      .cms-errorStatus{
        color: red;
        font-size: 12px;
      }
    }
  }
}