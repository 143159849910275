
.cms-addProduct{
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  height: calc(100% - 60px);
  overflow: hidden auto;
  .cms-imageContainer{
    width: 250px;
    height: 266px;
    padding: 10px;
    position: relative;
    background: #fff;
    border: solid 1px #767676;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    .cms-remove-image{
      width: 24px;
      height: 24px;
      position: absolute;
      right: 10px;
      top: 10px;
      background: #fff;
      border: solid 1px #c0c0c0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 8px;
      z-index: 9;
      cursor: pointer;
      box-sizing: border-box;
      i{
        display: flex;
        align-items: center;
        position: absolute;
        top: 5px;
      }
    }
    .cms-label{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 7px;
      font-weight: 600;
      i{
        font-size: 30px;
      }
    }
    input[type="file"] {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      font-size: 0;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      &::-webkit-file-upload-button {
        display: none;
      }
    }
    .cms-image-uploaded{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
    }
  }
  .cms-fieldContainer{
    display: flex;
    flex-direction: column;
    .cms-doubleRow{
      width: 100%;
      display: flex;
      gap: 40px;
    }
    .cms-singleRow{
      width: 100%;
    }
    .cms-fields{
      width: calc(100% - 250px);
    }
    .cms-field{
      display: flex;
      flex-direction: column;
      gap: 3px;
      margin-bottom: 20px;
      position: relative;
      label{
        font-weight: 600;
      }
      input{
        font-size: 14px;
        padding: 7px 10px;
        height: 32px;
        box-sizing: border-box;
        font-family: Inter;
      }
      textarea{
        font-size: 14px;
        padding: 7px 10px;
        box-sizing: border-box;
        font-family: Inter;
        resize: none;
      }
    }
  }
  .cms-footerButtons{
    display: flex;
    align-items: center;
    gap: 20px;
    button{
      height: 32px;
      cursor: pointer;
      padding: 7px 10px;
      box-sizing: border-box;
      font-family: Inter;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px #767676;
      gap: 4px;
    }
  }
}