.contatti{
  padding: 140px 0;
  position: relative;
  box-sizing: border-box;
  .titleContact{
    margin: 30px 0;
    h1{
      margin: 0;
      font-weight: bold;
      font-size: 25px;
      line-height: 35px;
      @media (min-width: 576px) {
        line-height: 50px;
      }
    }
  }
}