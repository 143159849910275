.searchComponent{
  padding: 140px 0;
  position: relative;
  box-sizing: border-box;
  .titleSearch{
    margin: 50px 0 30px;
    h1{
      margin: 0;
      font-size: 20px;
      line-height: 35px;
      @media (min-width: 576px) {
        line-height: 50px;
      }
    }
  }
}