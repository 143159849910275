
.cms-categories{
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  height: calc(100% - 60px);
  overflow: hidden auto;
  .cms-addCategories{
    margin-bottom: 24px;
    .cms-labelCategories{
      margin-bottom: 8px;
      font-weight: 600;
    }
    .cms-fieldContainer{
      display: flex;
      align-items: center;
      gap: 6px;
      input{
        font-size: 14px;
        padding: 7px 10px;
        height: 32px;
        box-sizing: border-box;
        font-family: Inter;
      }
      button{
        height: 32px;
        cursor: pointer;
        padding: 7px 10px;
        box-sizing: border-box;
        font-family: Inter;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 1px #767676;
      }
    }
  }
  .cms-table{
    width: 100%;
    display: table; 
    border-collapse: collapse;
    .cms-row {
      display: table-row;
      padding: 5px;
      &:hover{
        background-color: #c0c0c0;
      }
      .cms-cell{
        vertical-align: middle;
        padding: 7px 10px;
        border: solid 1px #e2e8f0;
        border-top: none;
        background: #fff;
        display: table-cell;
        gap: 5px;
        &.cms-heading{
          padding: 10px;
          font-weight: 600;
          background: #e2e8f0;
          border: solid 1px #e2e8f0;
        }
        label{
          width: fit-content;
          display: flex;
          align-items: center;
          position: relative;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          gap: 8px;
          input[type="checkbox"] {
            cursor: pointer;
            height: 20px;
            width: 20px;
            appearance: none;
            -webkit-appearance: none;
            display: flex;
            align-content: center;
            justify-content: center;
            font-size: 2rem;
            padding: 0.1rem;
            border: solid 1px #767676;
            border-radius: 4px;
          }
          input[type="checkbox"]:checked{
            background: #1e90ff;
            border: solid 1px #1e90ff;
          }
          input[type="checkbox"]:checked::before {
            font-family: "fontello";
            content: '\e88c';
            font-size: 11px;
            color: #fff;
          }
          input[type="checkbox"]:not(:checked):hover::before {
            font-family: "fontello";
            content: '\e88c';
            font-size: 11px;
            color: #1e90ff;
          }                    
        }
        button{
          height: 32px;
          cursor: pointer;
          padding: 7px 10px;
          box-sizing: border-box;
          font-family: Inter;
          display: flex;
          align-items: center;
          justify-content: center;
          border: solid 1px #767676;
        }
      }
    }
  }
}